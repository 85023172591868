/* MainScreen.css */

.main-screen {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.sidebar {
  width: 250px;
  background-color: #343a40;
  color: #ffffff;
  padding-top: 20px;
}
.border-element {
  border: 2px solid transparent;
  background-clip: padding-box; /* Ensures the background doesn't extend into the border */
  /* background-image: linear-gradient(to bottom, #3498db, #e74c3c); */
}
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.logo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.logo span {
  font-size: 18px;
  font-weight: bold;
}

.line {
  width: 90%;
  height: 1px;
  background-color: white;
  margin: 10px 0;
}

button {
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #28a745;
  color: white;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.menu li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Add relative positioning */
}

.menu li.active {
  background-color: #495057;
}

.menu li:hover {
  background-color: #495057;
}

.menu li span {
  margin-left: 10px;
}

/* Submenu styles */
.submenu {
  position: absolute;
  top: 0;
  left: 100%; /* Position submenu to the right of the main menu item */
  width: 200px; /* Adjust submenu width as needed */
  background-color: #343a40;
  display: none;
  z-index: 1; /* Ensure submenus appear on top */
}

.menu li:hover .submenu {
  display: block;
}

.submenu li {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.submenu li.active {
  background-color: #495057;
}

.submenu li:hover {
  background-color: #495057;
}

.submenu li span {
  margin-left: 10px;
  font-size: 14px;
  color: #ddd;
}

.content {
  flex: 1;
  padding: 20px;
}

.button_css{
  width: 15%;
}