/* src/App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  /* margin-top: 20px; */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Toastify__toast-body {
  width: 1000%;
}
h1 {
  margin-bottom: 20px;
}

.registration-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  width: 88%;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}
.registration-container .form-group {
  margin-bottom: 10px;
}

.registration-container label {
  display: block;
  font-weight: bold;
}

.registration-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.registration-container button {
  width: 100%;
  padding: 8px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
}

.registration-container button:hover {
  background-color: #0056b3;
}
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  /* margin-top: 20px; */
}

.logout-button {
  position: absolute;
  top: 7%;
  right: 0px;
  padding: 8px 16px;
  background-color: #38eb14;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  width: 5%;
}

.logout-button:hover {
  background-color: #0056b3;
}
.content-container {
  margin-top: 50px;
}
