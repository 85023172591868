.user-list-container {
    padding: 20px;
  }
  
  .user-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    padding: 0;
  }
  
  .user-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .user-item:hover {
    transform: translateY(-5px);
  }
  
  .user-info {
    font-size: 16px;
  }
  .user-list-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .user-list {
    flex: 1;
  }
  /* Example CSS */
.button-group {
  display: flex;
  gap: 8px;
}

.cancel-button {
  background-color: #ff6666;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}
/* Example CSS */
.button-group {
  display: flex;
  gap: 8px;
}

.edit-button {
  background-color: #66a3ff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

.delete-button {
  background-color: #ff6666;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

/* Other button styles... */

.save-button {
  background-color: #66cc66;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

  .user-registration {
    flex: 1;
  }
  select {
    appearance: none;
    width: 100%;
    min-width: 200px;
    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 10px center;
    background-size: 18px 18px;margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }