#invoice{
    padding: 30px;
}

.invoice {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding: 15px
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #3989c6
}

.invoice .company-details {
    text-align: right
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .contacts {
    margin-bottom: 20px
}

.invoice .invoice-to {
    text-align: left
}

.invoice .invoice-to .to {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .invoice-details {
    text-align: right
}

.invoice .invoice-details .invoice-id {
    margin-top: 0;
    color: #3989c6
}

.invoice main {
    padding-bottom: 50px
}

.invoice main .thanks {
    margin-top: -100px;
    font-size: 2em;
    margin-bottom: 50px
}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid #3989c6
}

.invoice main .notices .notice {
    font-size: 1.2em
}

.invoice table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px
}

.invoice table td,.invoice table th {
    padding: 10px;
    background: #eee;
    border-bottom: 1px solid #fff
}

.invoice table th {
    white-space: nowrap;
    /* font-weight: 400; */
    font-size: 10px;
    text-align: left;
}

.invoice table td h3 {
    margin: 0;
    font-weight: 300;
    color: #3989c6;
    font-size:9px
}

.invoice table .qty,.invoice table .total,.invoice table .unit {
    text-align: left;
    font-size:10px;
}

.invoice table .no {
    color: #fff;
    font-size: 12px;
    width: 8%;
    background:#8c2e53
}

.invoice table .unit {
    background: #ddd
}

.invoice table .total {
    background:#8c2e53;
    color: #fff;
    width:10%;
}

.invoice table tbody tr:last-child td {
    border: none
}

.invoice table tfoot td {
    background: 0 0;
    border-bottom: none;
    white-space: nowrap;
    text-align: right;
    padding: 10px 20px;
    font-size: 12px;
    border-top: 1px solid #aaa
}

.invoice table tfoot tr:first-child td {
    border-top: none
}

.invoice table tfoot tr:last-child td {
    color:#8c2e53;
    font-size: 12px;
    border-top: 1px solid #3989c6
}

.invoice table tfoot tr td:first-child {
    border: none
}

.invoice footer {
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0
}

@media print {
    .invoice {
        font-size: 11px!important;
        overflow: hidden!important
    }

    .invoice footer {
        position: absolute;
        bottom: 10px;
        page-break-after: always
    }

    .invoice>div:last-child {
        page-break-before: always
    }
    .print-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 10px;
        background-color: #fff; /* Optional background color */
        padding: 10px; /* Optional padding */
      }
}

.page-content {
    /* Add styling for the content on the page */
    margin-bottom: 100px; /* Adjust this margin based on your needs */
}

.footer {
    /* Add styling for the footer */
    position: fixed;
    bottom:8%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 20px;
    text-align: left;
    font-size: 12px
}