/* src/styles.css */
/* Reset some default styles */
body, h1, h2, ul, li {
    margin: 0;
    padding: 0;
  }
  
  /* Apply basic styling */
  body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    margin-bottom: 20px;
  }
  
  li {
    padding: 10px;
    /* border: 1px solid #ddd;
    background-color: #fff; */
    margin-bottom: 5px;
  }
  
  a {
    text-decoration: none;
    color:rgb(25, 118, 210);;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  form {
    margin-top: 20px;
  }
  
  label, input {
    display: block;
    margin-bottom: 10px;
  }
  
  input {
    padding: 5px;
    width: 100%;
  }
  
  .error {
    color: red;
  }
   