
.class-management-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Styling for the main heading */
  .main-heading {
    text-align: center;
  }
  .main-heading {
    font-size: 24px;
    margin-bottom: 20px;
    position: relative;
  }
  .main-heading::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }  
  /* Styling for the back-to-list link */
  .back-to-list-link {
    display: block;
    margin-top: 20px;
    text-align: center;
  }
  .class-management-container button{
    width: 22%;
  }
  .form-content button{
    width: 42%;
  }