/* Styles for the form */
form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  /* itemForm.css */

/* Your existing styles... */

/* Loading spinner overlay */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 9999; /* Make sure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Separate styles for single item form */
.single-item-form {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.single-item-form label {
  width: 100px;
}

.single-item-form input[type='text'] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.file-input {
  display: none;
}


.upload-spinner {
  margin-left: 8px;
}

/* Separate styles for single item form */
.single-item-form {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.single-item-form label {
  width: 100px;
}

.single-item-form input[type='text'] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.file-input {
  display: none;
}

.upload-button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100px; /* Set a fixed width for the Upload button */
}

.upload-button:hover {
  background-color: #45a049;
}

.upload-spinner {
  margin-left: 8px;
}

/* Separate styles for single item form */
.single-item-form {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.single-item-form label {
  width: 100px;
}

.single-item-form input[type='text'] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Adjust the styles as needed for your layout */
.title-bar {
  background: linear-gradient(to bottom, #2d2d2d, #000000);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-bar h2 {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); /* Add a text shadow for depth */
}

.title-bar p {
  margin: 0;
  font-size: 16px;
  color: #aaa;
}



/* Excel format table header */
.excel-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.excel-header-item {
  flex: 1;
  text-align: center;
}
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-row label {
  font-weight: bold;
  min-width: 150px; /* Adjust the minimum width of the labels as needed */
  margin-right: 10px; /* Add some spacing between label and input */
}
/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; /* Spinner text color */
  font-size: 20px;
  padding: 16px;
  background-color: #333; /* Spinner background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Your existing styles... */

  /* Styles for the notification */
  .notification {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
  }
  .save-button {
    padding: 8px 15px; /* Adjust the padding to make the button smaller */
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .save-icon {
    margin-right: 8px;
  }

  