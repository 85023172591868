/* ItemList.css */

/* Style for the list container */
.item-list-container {
    width: 100%;
    margin: 0 auto;
  }
  
  /* Style for the list heading */
  .item-list-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Style for the table */
  .item-list-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Style for the table header */
  .item-list-table th {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  /* Style for each table row */
  .item-list-table tr {
    border-bottom: 1px solid #ccc;
  }
  
  /* Style for the table data cells */
  .item-list-table td {
    padding: 10px;
  }
  
  /* Style for the delete button */
  .delete-button {
    background-color: #ff3b3b;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #ff0000;
  }
  
  .item-list-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .item-list-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .item-list-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .item-list-table th,
  .item-list-table td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .item-list-table th {
    background-color: #f0f0f0;
    font-weight: bold;
    text-align: left;
  }
  
  .delete-button {
    background-color: #ff6347;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #ff4433;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  /* Add this CSS in the 'itemForm.css' file */

.item-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


/* Style the file upload */
.file-input-container {
  display: flex;
  align-items: center;
}

.file-upload-label {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #0acf4c;
  border-radius: 4px;
  cursor: pointer;
}

.file-upload-label:hover {
  background-color: #e0e0e0;
}

.file-input {
  display: none;
}

.upload-icon {
  margin-right: 8px;
}

/* Style the save button */
.save-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.save-button:hover {
  background-color: #45a049;
}

.save-icon {
  margin-right: 8px;
}

/* Loading spinner overlay */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  display: flex;
  align-items: center;
  font-size: 18px;
}

/* Adjust the styles as needed for your layout */

  .pagination a {
    padding: 8px 12px;
    text-decoration: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .pagination a.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 18px;
    color: #007bff;
  }
  